import React from 'react'

import './popschedule1.css'

const Popschedule1 = ({CloseCancelScreenY}) => {
  return (
    <div className="popschedule1-container1">
      <div className="popschedule1-container2">
        <span className="popschedule1-text1">Warning</span>
        <div className="popschedule1-line"></div>
        <span className="popschedule1-text2">
          As there are less than 24 hours until your clean, you are not eligible
          for a refund
        </span>
        <span className="popschedule1-text3">Learn more on our FAQs</span>
        <div className="popschedule1-container3">
          <button type="button" className="popschedule1-button1 button" onClick={CloseCancelScreenY}>
            <span className="popschedule1-text4">Go Back</span>
          </button>
          <button type="button" className="popschedule1-button2 button" onClick={CloseCancelScreenY}>
            <span className="popschedule1-text5">Proceed to cancel</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Popschedule1
