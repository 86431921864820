import React from 'react'

import './popschedule.css'

const Popschedule = ({CloseCancelScreen}) => {
  return (
    <div className="popschedule-container1">
      <div className="popschedule-container2">
        <span className="popschedule-text1">Warning</span>
        <div className="popschedule-line"></div>
        <span className="popschedule-text2">
          As there are less than 48 hours until your clean, you are only
          eligible for a 50% refund
        </span>
        <span className="popschedule-text3">Learn more on our FAQs</span>
        <div className="popschedule-container3">
          <button type="button" className="popschedule-button1 button" onClick={CloseCancelScreen}>
            <span className="popschedule-text4">Go Back</span>
          </button>
          <button type="button" className="popschedule-button2 button" onClick={CloseCancelScreen}>
            <span className="popschedule-text5">Proceed to cancel</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Popschedule
